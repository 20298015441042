import { call, put, takeLatest, select } from "redux-saga/effects";
import * as ShopApi from "./ShopApi";
import { selectCartItems, ShopSlice } from "./ShopSlice";
import { CartItemData, DominoDataTypes, OrderData, ShopProductData } from "./ShopData";
import { ShopProductModel } from "./ShopModel";
import { handleException } from "../../common/SagaHelper";
import { CartItem } from "./ShopState";
import { mapCartModelsToData } from "./ShopMapper";

const tg = window.Telegram.WebApp;

export function* ShopSaga() {
  yield takeLatest(ShopSlice.actions.getShopProductList, getShopProductList);
  yield takeLatest(ShopSlice.actions.getShopProductListDraft, getShopProductListDraft);
  yield takeLatest(ShopSlice.actions.createOrder, createOrder);
}

export function* getShopProductList() {
  try {
    const data: ShopProductData[] = yield call(ShopApi.getShopProducts);
    const model: ShopProductModel[] = [...data];
    if (model.length > 0) {
      yield put(ShopSlice.actions.getShopProductListCompleted(model));
    }
  } catch (e: unknown) {
    yield handleException(e);
  }
}

export function* getShopProductListDraft() {
  try {
    const data: ShopProductData[] = yield call(ShopApi.getShopProductsDraft);
    const model: ShopProductModel[] = [...data];
    if (model.length > 0) {
      yield put(ShopSlice.actions.getShopProductListCompleted(model));
    }
  } catch (e: unknown) {
    yield handleException(e);
  }
}

export function* createOrder() {
  try {
    const cartItems: CartItem[] = yield select(selectCartItems);
    const cartItemsData: CartItemData[] = mapCartModelsToData(cartItems);
    const order: OrderData = yield call(ShopApi.createOrder, cartItemsData);

    tg.sendData(
      JSON.stringify({
        dominoDataType: DominoDataTypes.SHOPPING_CART,
        orderId: order.id,
      }),
    );
  } catch (e: unknown) {
    yield handleException(e);
  }
}
