import { CartItem } from "./ShopState";
import { CartItemData, CartItemOptionData } from "./ShopData";

export const mapCartModelsToData = (model: CartItem[]): CartItemData[] => {
  return model.map(item => {
    const optionKeys = Object.keys(item.selectedOptions);

    const options: CartItemOptionData[] = optionKeys.map(optionKey => {
      const value = item.selectedOptions[optionKey];
      return {
        name: optionKey,
        values: value.map(el => {
          return {
            name: el.name,
            adPrice: el.addPrice,
          };
        }),
      };
    });

    return {
      ...item,
      selectedOptions: options,
    };
  });
};
