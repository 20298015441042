import React, { useState, useEffect } from "react";
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  Image,
  Flex,
  Text,
  Stack,
  Checkbox,
  RadioGroup,
  Radio,
  Button,
} from "@chakra-ui/react";
import s from "./OptionsModal.module.scss";
import { ShopProductModel } from "../Shop/ShopModel";
import { useTranslation } from "react-i18next";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  product: ShopProductModel;
  addToCart: (selectedOptions: { [key: string]: Array<{ name: string; addPrice: number | null }> }, quantity: number) => void;
}

export const OptionsModal: React.FC<Props> = ({ isOpen, onClose, product, addToCart }) => {
  const t = useTranslation("translation").t;
  const [selectedOptions, setSelectedOptions] = useState<{ [key: string]: Array<{ name: string; addPrice: number | null }> }>({});
  const [optionsValid, setOptionsValid] = useState(false);
  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    if (isOpen) {
      setSelectedOptions({});
      setQuantity(1);
    }
  }, [isOpen]);

  useEffect(() => {
    const isValid = product.options.filter(option => option.required).every(option => selectedOptions[option.name]?.length);

    setOptionsValid(isValid);
  }, [selectedOptions, product.options]);

  const handleOptionChange = (optionName: string, value: Array<{ name: string; addPrice: number | null }>) => {
    setSelectedOptions(prev => ({
      ...prev,
      [optionName]: value,
    }));
  };

  const handleAddToCart = () => {
    addToCart(selectedOptions, quantity);
  };

  const handleQuantityChange = (type: "increment" | "decrement") => {
    setQuantity(prevQuantity => {
      if (type === "increment") {
        return prevQuantity + 1;
      } else {
        return prevQuantity > 1 ? prevQuantity - 1 : 1;
      }
    });
  };

  const renderOptions = () => {
    return product.options.map(option => (
      <Flex key={option.name} direction="column" mb={4}>
        <Flex>
          <Text fontWeight="bold">{option.name}</Text>
          {option.required && <Text color="red.500">*</Text>}
        </Flex>
        {option.multichoice ? (
          <Stack>
            {option.values.map(value => (
              <Checkbox
                key={value.name}
                value={value.name}
                onChange={e => {
                  const values = (selectedOptions[option.name] as Array<{ name: string; addPrice: number | null }>) || [];
                  const newValues = e.target.checked
                    ? [...values, { name: value.name, addPrice: value.adPrice }]
                    : values.filter(v => v.name !== value.name);
                  handleOptionChange(option.name, newValues);
                }}
                size="lg"
                colorScheme="orange"
              >
                <Flex gap="10px" align="center">
                  <Text fontSize="16px">{value.name}</Text>
                  <Text fontSize="12px" color={"gray.500"}>
                    {value.adPrice && `+${value.adPrice}₽`}
                  </Text>
                </Flex>
              </Checkbox>
            ))}
          </Stack>
        ) : (
          <RadioGroup
            onChange={value => {
              const addPrice = option.values.find(el => el.name === value)?.adPrice;
              handleOptionChange(option.name, [{ name: value, addPrice: addPrice ? addPrice : null }]);
            }}
            value={selectedOptions[option.name] ? (selectedOptions[option.name][0].name as string) : ""}
          >
            <Stack>
              {option.values.map(value => (
                <Radio size="lg" colorScheme="orange" key={value.name} value={value.name}>
                  <Flex gap="10px" align="center">
                    <Text fontSize="16px">{value.name}</Text>
                    <Text fontSize="12px" color={"gray.500"}>
                      {value.adPrice && `+${value.adPrice}₽`}
                    </Text>
                  </Flex>
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
        )}
      </Flex>
    ));
  };

  return (
    <Flex>
      <Drawer placement="bottom" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent className={s.drawer}>
          <DrawerBody className={s.drawerBody}>
            <Image className={s.image} src={product.imageUrl} alt={product.name} />
            <Flex direction="column" p={4}>
              <Text fontWeight="bold" mb={2}>
                {product.name}
              </Text>
              <Text mb={4}>{product.description}</Text>
              {renderOptions()}
              <Flex w="100%" justifyContent="center" gap="20px">
                <Flex justify="center" align="center" gap="10px">
                  <Button className={s.minusButton} onClick={() => handleQuantityChange("decrement")}>
                    -
                  </Button>
                  <Text fontSize="16px">{quantity}</Text>
                  <Button className={s.addButton} onClick={() => handleQuantityChange("increment")}>
                    +
                  </Button>
                </Flex>
                <Button
                  size="sm"
                  backgroundColor={"#ecac41"}
                  w="50%"
                  onClick={handleAddToCart}
                  isDisabled={!optionsValid}
                  fontSize="16px"
                >
                  {t("Add")}
                </Button>
              </Flex>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};
