import { Action, combineReducers } from "@reduxjs/toolkit";
import NotificationSlice from "../notifications/NotificationSlice";
import LoadingSlice from "../loading/LoadingSlice";
import { ApplicationReducerState, ApplicationState } from "./ApplicationState";
import ShopSlice from "../../components/Shop/ShopSlice";

export const rootReducer = (state: ApplicationState | undefined, action: Action) => {
  const combinedReducers = combineReducers<ApplicationReducerState>({
    notificationState: NotificationSlice,
    loadingState: LoadingSlice,
    shopState: ShopSlice,
  });
  return combinedReducers(state, action);
};
