import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../state/store";
import { NotificationModel } from "./NotificationModel";
import { NotificationState } from "./NotificationState";

const initialState: NotificationState = {};

export const notificationSlice = createSlice({
  name: "notificationSlice",
  initialState,
  reducers: {
    notify: (state: NotificationState, action: PayloadAction<NotificationModel>) => {
      return {
        ...state,
        notification: action.payload,
      };
    },
    clearNotify: (state: NotificationState) => {
      return {
        ...initialState,
      };
    },
  },
});

export const { notify, clearNotify } = notificationSlice.actions;

export default notificationSlice.reducer;

export const selectNotification = (state: RootState) => state.notificationState.notification;
