import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./locales/en/translation.json";
import translationRU from "./locales/ru/translation.json";

const resources = {
  en: translationEN,
  ru: translationRU,
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    returnNull: false,
    resources,
    fallbackLng: "en",
    ns: ["translation", "validation"],
    defaultNS: "translation",
    keySeparator: ".",
    nsSeparator: "|",
    load: "languageOnly",
  });

export default i18n;
