import { useEffect, useState, useRef } from "react";
import { Box, Button, Flex, Grid, Heading, Link } from "@chakra-ui/react";
import { useTelegram } from "../../common/hooks/useTelegram";
import { useAppDispatch, useAppSelector } from "../../common/state/store";
import {
  getShopProductList,
  getShopProductListDraft,
  selectCartItems,
  selectCartPrice,
  selectShopProductList,
  setIsDraft,
} from "./ShopSlice";
import s from "./ShopContainer.module.scss";
import { ShopItem } from "./components/ShopItem/ShopItem";
import { ShopProductModel } from "./ShopModel";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface Props {
  draft: boolean;
}

export const ShopContainer = ({ draft: draftProp }: Props) => {
  const t = useTranslation("translation").t;
  const dispatch = useAppDispatch();
  const nav = useNavigate();
  const { tg } = useTelegram();

  const products = useAppSelector(selectShopProductList);
  const cartPrice = useAppSelector(selectCartPrice);
  const cart = useAppSelector(selectCartItems);

  const [activeCategory, setActiveCategory] = useState<string>("");
  const [popularProducts, setPopularProducts] = useState<ShopProductModel[]>([]);

  const categoryRefs = useRef<{ [key: string]: HTMLElement | null }>({});
  const activeCategoryRefs = useRef<{ [key: string]: HTMLElement | null }>({});

  useEffect(() => {
    dispatch(setIsDraft(draftProp));

    if (draftProp) {
      dispatch(getShopProductListDraft());
    } else {
      dispatch(getShopProductList());
    }
  }, [dispatch, draftProp]);

  useEffect(() => {
    tg.BackButton.hide();
    tg.MainButton.setText(`${t("YOUR ORDER")} ₽${cartPrice}`);
    if (cart && cart.length > 0) {
      tg.MainButton.show();
      tg.MainButton.onClick(() => {
        nav(`/shop/shoppingCart`);
      });
    } else {
      tg.MainButton.hide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nav, tg, cart]);

  useEffect(() => {
    const popular = products ? products.filter(product => product.isPopular) : [];
    setPopularProducts(popular);
  }, [products]);

  const groupProductsByCategory = (products: ShopProductModel[]) => {
    return products.reduce(
      (acc, product) => {
        const category = product.category;
        if (!acc[category]) {
          acc[category] = [];
        }
        acc[category].push(product);
        return acc;
      },
      {} as { [key: string]: ShopProductModel[] },
    );
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setActiveCategory(entry.target.id || entry.target.className.replace(/css-0/gm, "").trim());
          }
        });
      },
      { threshold: 0.3, rootMargin: "0px 0px -80% 0px", root: document.querySelector("#root") },
    );

    Object.values(categoryRefs.current).forEach(ref => {
      if (ref) {
        observer.observe(ref);
      }
    });

    const currentCategoryRefs = categoryRefs.current;

    return () => {
      Object.values(currentCategoryRefs).forEach(ref => {
        if (ref) {
          observer.unobserve(ref);
        }
      });
    };
  }, [products]);

  useEffect(() => {
    if (activeCategory && activeCategoryRefs.current && activeCategoryRefs.current[activeCategory]) {
      const timer = setTimeout(() => {
        activeCategoryRefs.current[activeCategory]?.scrollIntoView({
          behavior: "smooth",
          inline: "center",
          block: "nearest",
        });
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [activeCategory]);

  if (!products) {
    return <></>;
  }

  const productsByCategory = groupProductsByCategory(products);
  let categories = Object.keys(productsByCategory);

  if (popularProducts.length > 0) {
    categories = ["Популярные", ...categories];
  }

  return (
    <Flex className={`${s.container} container`}>
      {categories && (
        <Flex className={`${s.categoryContainer} categoryContainer`}>
          {categories.map(category => (
            <Link
              href={`#${category}`}
              className={`${s.category} ${activeCategory === category ? s.selectedCategory : ""}`}
              key={category}
              ref={el => (activeCategoryRefs.current[category] = el)}
            >
              {category}
            </Link>
          ))}
        </Flex>
      )}

      <Box pt="5px" id="container_id">
        {categories.map((category, ind) => (
          <Box
            height={ind === categories.length - 1 ? `calc(100dvh - ${cart && cart.length > 0 ? "110px" : "70px"})` : "auto"}
            scrollMarginTop="60px"
            key={category}
            pb="30px"
            id={category}
          >
            <Box className={category} ref={el => (categoryRefs.current[category] = el)}>
              <Heading as="h2" size="lg" mb="10px">
                {category}
              </Heading>
            </Box>
            <Grid templateColumns="repeat(3, 1fr)" columnGap="20px" rowGap="20px">
              {(category === "Популярные" ? popularProducts : productsByCategory[category]).map(product => {
                return <ShopItem key={product.id} product={product} />;
              })}
            </Grid>
          </Box>
        ))}
      </Box>
      {cart && cart?.length > 0 && tg.platform === "unknown" && (
        <Button onClick={() => nav(`/shop/shoppingCart`)}>{`${t("YOUR ORDER")} ₽${cartPrice}`}</Button>
      )}
    </Flex>
  );
};
