import { AppSettings } from "../../common/AppSettings.js";
import { httpGet, httpPost } from "../../common/BaseApi";
import { CartItemData } from "./ShopData";

declare const appSettings: AppSettings;

export const getShopProducts = () => {
  return httpGet(`${appSettings.apiBaseUrl}/products`);
};

export const getShopProductsDraft = () => {
  return httpGet(`${appSettings.apiBaseUrl}/productsDraft`);
};

export const createOrder = (cartItems: CartItemData[]) => {
  return httpPost(`${appSettings.apiBaseUrl}/order`, cartItems);
};
