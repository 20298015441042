import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../state/store";
import { LoadingState } from "./LoadingState";

const initialState: LoadingState = {};

export const LoadingSlice = createSlice({
  name: "loadingSlice",
  initialState,
  reducers: {
    begin: state => {
      const newState = { ...state };
      const loaders = state["default"] ? state["default"].loaders : 0;
      newState["default"] = {
        isLoading: loaders >= 0,
        loaders: loaders < 0 ? 1 : loaders + 1,
      };
      return newState;
    },
    beginScope: (state, action: PayloadAction<string>) => {
      const newState = { ...state };
      const loaders = state[action.payload] ? state[action.payload].loaders : 0;
      newState[action.payload] = {
        isLoading: loaders >= 0,
        loaders: loaders < 0 ? 1 : loaders + 1,
      };
      return newState;
    },
    complete: state => {
      const newState = { ...state };
      const loaders = state["default"] ? state["default"].loaders : 0;
      newState["default"] = {
        isLoading: loaders > 1,
        loaders: loaders > 0 ? loaders - 1 : 0,
      };
      return newState;
    },
    completeScope: (state, action: PayloadAction<string>) => {
      const newState = { ...state };
      const loaders = state[action.payload] ? state[action.payload].loaders : 0;
      newState[action.payload] = {
        isLoading: loaders > 1,
        loaders: loaders > 0 ? loaders - 1 : 0,
      };
      return newState;
    },
  },
});

export const { begin, beginScope, complete, completeScope } = LoadingSlice.actions;

export const selectLoading = (state: RootState) => {
  return state.loadingState;
};

export default LoadingSlice.reducer;
