export const getURLSearchParams = () => {
  const params = new URLSearchParams(window.location.search);
  const orgId = params.get("orgId");
  const lng = params.get("lng");

  if (orgId) {
    sessionStorage.setItem("orgId", orgId);
  }

  if (lng) {
    sessionStorage.setItem("lng", lng);
  }
};
