import { Route, RouterProvider, Routes, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import { ShoppingCartContainer } from "./components/ShoppingCart/ShoppingCartContainer";
import { ShopContainer } from "./components/Shop/ShopContainer";
import "./App.css";
import { useEffect } from "react";
import { useTelegram } from "./common/hooks/useTelegram";
import { getURLSearchParams } from "./common/utils/getURLSearchParams";

function App() {
  const { tg } = useTelegram();

  useEffect(() => {
    tg.ready();
    tg.expand();
    tg.disableVerticalSwipes();
  }, [tg]);

  useEffect(() => {
    getURLSearchParams();
  }, []);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route
          path="/*"
          element={
            <Routes>
              <Route path="/shop" element={<ShopContainer draft={false} />} />
              <Route path="/draft" element={<ShopContainer draft={true} />} />
              <Route path="/shop/shoppingCart" element={<ShoppingCartContainer />} />
            </Routes>
          }
        ></Route>
      </>,
    ),
  );

  return <RouterProvider router={router} />;
}

export default App;
