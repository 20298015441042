import { ShopProductModel } from "./ShopModel";

export interface ShopProductsListData {
  shopList: ShopProductData[];
}

export interface ShopProductData {
  id: string;
  category: string;
  version: string;
  name: string;
  description: string;
  price: number;
  imageUrl: string;
  isPopular: boolean;
  options: ShopProductOptionData[];
}

export interface ShopProductOptionData {
  name: string;
  required: boolean;
  multichoice: boolean;
  values: ShopProductOptionValuesData[];
}

export interface ShopProductOptionValuesData {
  name: string;
  adPrice: number;
}

export interface OrderData {
  id: string;
}

export interface CartItemData {
  product: ShopProductModel;
  quantity: number;
  selectedOptions: CartItemOptionData[];
}

export interface CartItemOptionData {
  name: string;
  values: Array<{ name: string; adPrice: number | null }>;
}

export enum DominoDataTypes {
  SHOPPING_CART = "SHOPPING_CART",
}
